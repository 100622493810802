const proSettings = {
  navTheme: 'light',
  primaryColor: '#1890ff',
  layoutBodyBackground: '#f8f8f8',
  layout: 'side',
  contentWidth: 'Fluid',
  fixedHeader: true,
  fixSiderbar: true,
  title: 'xarefit',
  pwa: false,
  iconfontUrl: '',
  layout: "mix",
  menu: {
    locale: true
  },
  headerHeight: 48,
  splitMenus: false,
  pageSize: 10
};
export default proSettings;
