import request from 'umi-request';

// export async function queryCurrent(id) {
//   return request('/api/currentUser', id);
// }
export async function queryUser(payload) {
  return request('/api/xarefit/getUser', {
    method: 'get',
    params: payload,
  });
}
export async function queryFakeList(params) {
  return request('/api/fake_list', {
    params,
  });
}
