import { querySiteStatus } from './service';

const Model = {
  namespace: 'site',
  state: {
    data: [],
  },
  effects: {
    *fetchSiteStatus(_, { call, put }) {
      const { id } = _.payload;
      const response = yield call(querySiteStatus, id);
      yield put({
        type: 'save',
        payload: {
          data: response,
        },
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};
export default Model;
