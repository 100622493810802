import { setLocale } from 'umi';

export async function getInitialState() {
  setLocale('zh-TW');
  let myGreeting = setTimeout(function sayHi() {
    return {
      gh: 1000,
    };
  }, 2000);
  return myGreeting;
}
