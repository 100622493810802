
import request from '@/utils/request';



const XAREFIT_API_ENDPOINT = '/api/xarefit/notifications'
const method = 'POST';

// query notifications
export async function queryNotifications(params) {
  return request(`${XAREFIT_API_ENDPOINT}/list`, {
    method,
    data: { ...params },
  });
}

// query notification list
export async function queryNotificationsWithId(params) {
  return request(`${XAREFIT_API_ENDPOINT}/listWithId`, {
    method,
    data: { ...params },
  });
}


export async function createNotification(params) {
  return request(`${XAREFIT_API_ENDPOINT}/event`, {
    method: 'POST',
    data: { ...params }
  })
}

export async function buildSendNotifyListAtServer(params) {
  const { id } = params;
  return request(`${XAREFIT_API_ENDPOINT}/${id}/generate`, {
    method: 'PATCH'
  })
}