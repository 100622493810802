import { queryCurrent, query as queryUsers } from '@/services/member';

const MemberModel = {
  namespace: 'member',
  state: {
    currentUser: {},
    queryMemberCount: 0,
    uniqueMemberCount: 0,
  },
  effects: {
    *fetch(_, { call, put }) {
      const response = yield call(queryUsers);
      yield put({
        type: 'save',
        payload: response,
      });
    },

    *fetchCurrent(_, { call, put }) {
      const response = yield call(queryCurrent);
      yield put({
        type: 'saveCurrentUser',
        payload: response,
      });
    },
  },
  reducers: {
    updateCountAfterRequest(state, action) {
      // state 是原本的 dev model
      // action payload 是 dispatch 所帶入的值
      // return 是回傳至 member 這個 model 所定義的 state
      const { queryMemberCount, uniqueMemberCount } = action.payload;
      return { ...state, queryMemberCount, uniqueMemberCount };
    },

    saveCurrentUser(state, action) {
      return { ...state, currentUser: action.payload || {} };
    },

    changeNotifyCount(
      state = {
        currentUser: {},
      },
      action,
    ) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
  },
};
export default MemberModel;
