import {
  queryNotifications,
} from '@/services/appsetting';
import moment from 'moment';
import { message, notification } from 'antd';
import { connect, useIntl, FormattedMessage, history } from 'umi';
import openNotification from '@/utils/notify';

const namespace = 'appsetting'
const AppSettingModel = {
  namespace,
  defaultValue: {
    pushNotifications: [],
  },
  actionType: {
    SAVE_ITEM: `${namespace}/save`,
  },
  state: {},
  effects: {
    *fetch(_, { call, put }) {
      try {
        const response = yield call(queryNotifications, _.payload, { method: 'POST' });
        yield put({
          type: 'save', payload: {
            coupon: response.status.data ? formattedDate(response.status.data) : response.status.data,
            status: { ...response.status, name: 'fetch', total: response.status.total }
          }
        });
        message.success('fetch success');
      } catch (e) {
        console.log(e)
        message.error(`${e}`);
      }
    },
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
}

export default AppSettingModel;

export const SNO_TYPE = {
  FIXED_NO: 0,
  NOT_FIXED_NO: 1
}

export const COUPON_STATUS = {
  DRAFT: 0,
  PUBLISHED: 1,
  CLOSED: 2
}

export const EXPIRE_TYPE = {
  SET_BY_PERIOD: 1,
  SET_BY_DAYS: 2
}

export const DISCOUNT_TYPE = {
  BY_PERCENT: 1,
  BY_MONEY: 0
}