/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { extend } from 'umi-request';
import { notification } from 'antd';
// import { getUserToken, saveUserToken, clearAuthority } from './authority';
import { getCookieByClient } from './utils';

const codeMessage = {
  200: '服務器成功返回請求的數據。 ',
  201: '新建或修改數據成功。 ',
  202: '一個請求已經進入後台排隊（異步任務）。 ',
  204: '刪除數據成功。 ',
  400: '發出的請求有錯誤，服務器沒有進行新建或修改數據的操作。 ',
  401: '用戶沒有權限（令牌、用戶名、密碼錯誤）。 ',
  403: '用戶得到授權，但是訪問是被禁止的。 ',
  404: '發出的請求針對的是不存在的記錄，服務器沒有進行操作。 ',
  406: '請求的格式不可得。 ',
  410: '請求的資源被永久刪除，且不會再得到的。 ',
  422: '當創建一個對象時，發生一個驗證錯誤。 ',
  500: '服務器發生錯誤，請檢查服務器。 ',
  502: '網關錯誤。 ',
  503: '服務不可用，服務器暫時過載或維護。 ',
  504: '網關超時。 ',
};
/**
 * 异常处理程序
 */

const errorHandler = (error) => {
  const { response } = error;
  console.log(response);
  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    notification.error({
      message: `請求錯誤 ${status}`,
      description: errorText,
    });
  } else if (!response) {
    notification.error({
      description: '您的網路發生異常，無法連結伺服器',
      message: '網路異常',
    });
  }

  return response;
};
/**
 * 配置request请求时的默认参数
 */

const request = extend({
  errorHandler,
  // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie
});

const TOKEN_URL = '/api/login/xarefit/token';

export const getUserToken = () => request(TOKEN_URL)
export const saveUserToken = () => { }
export const clearAuthority = () => { }

// const API_ENDPOINT = 'https://dev-api.xarefit.com'

request.interceptors.request.use(async (url, options) => {
  console.log(`url: ${url}`)
  console.log(options)
  // if login after
  // let loggedin = true;
  // const loggedin = getCookieByClient('login-session');
  // if (url !== TOKEN_URL) {
  //   let token = '';
  //   if (token) {
  //     //如果有token 就走token邏輯
  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //     };
  //     return ({
  //       url: url,
  //       options: { ...options, headers: headers },
  //     });
  //   } else {
  //     let tokenPayload = await getUserToken();
  //   }
  // }
  return ({
    url: url,
    options: options,
  });
})

// 在请求头中添加acess_token https://juejin.cn/post/6844903972545757192
// https://blog.csdn.net/weixin_41753520/article/details/98317567
// request拦截器, 改变url 或 options.
// request.interceptors.request.use(async (url, options) => {

//   let c_token = localStorage.getItem("Cookie");
//   console.log(`c_token ${c_token}`)
//   if (c_token) {
//     const headers = {
//       'Content-Type': 'application/json',
//       'Accept': 'application/json',
//       'Cookie': c_token
//     };
//     return (
//       {
//         url: url,
//         options: { ...options, headers: headers },
//       }
//     );
//   } else {
//     const headers = {
//       'Content-Type': 'application/json',
//       'Accept': 'application/json',
//       'Cookie': c_token
//     };
//     return (
//       {
//         url: url,
//         options: { ...options },
//       }
//     );
//   }

// })

// // response拦截器, 处理response
// request.interceptors.response.use((response, options) => {
//   let token = response.headers.get("Cookie");
//   console.log(`token ${token}`)
//   if (token) {
//     localStorage.setItem("Cookie", token);
//   }
//   return response;
// });

export default request;
