import FormData from 'form-data';
import _ from 'lodash';
import { parse } from 'querystring';
import moment from 'moment';

/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const { API_ENDPOINT } = process.env;

const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const XAREFIT_API_ENDPOINT = '/api/xarefit'
export const method = 'POST';

export const isUrl = (path) => reg.test(path);
export const isAntDesignPro = () => {
  if (ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
    return true;
  }

  return window.location.hostname === 'preview.pro.ant.design';
}; // 给官方演示站点用，用于关闭真实开发环境不需要使用的特性

export const isAntDesignProOrDev = () => {
  const { NODE_ENV } = process.env;

  if (NODE_ENV === 'development') {
    return true;
  }

  return isAntDesignPro();
};
export const getPageQuery = () => parse(window.location.href.split('?')[1]);

export const fetchWithFitbutler = async ({ methods, queries, cookies, defaultOptions = {} }) => {
  let queryString = '';
  const queryParams = _.extend({}, methods);
  Object.keys(queryParams).forEach((key, idx) => {
    queryString += `${idx ? '&' : ''}${key}=${queryParams[key]}`;
  });
  const reqUrl = `${FITBULTER_API_ENDPOINT}?${queryString}`;
  options = {
    method: 'POST',
    body: {},
    headers: {
      'User-Agent': 'Mozilla/5.0',
    },
  };
  options = _.extend({}, options, defaultOptions);

  let formData = new FormData();
  Object.keys(queries).forEach((queryKey) => {
    formData.append(queryKey, queries[queryKey]);
  });
  options.body = formData;
  cookies
    ? _.extend(options.headers, { Cookie: cookies })
    : _.extend(options, { credentials: 'include' });
  const list = await fetch(reqUrl, options);
  const data = await list.json();
  return data;
};

export const getCookieByClient = (name) => {
  // Split cookie string and get all individual name=value pairs in an array
  const cookieArr = document.cookie.split(';');

  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i++) {
    let cookiePair = cookieArr[i].split('=');

    /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
    if (name == cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
};

export const getCookie = (req) => {
  const cookieString = req.headers.cookie;
  return decodeURIComponent(cookieString);
};

export const downloadCSV = (filename = "filename.csv", csvString) => {
  const blob = new Blob([csvString]);
  const a = window.document.createElement("a");
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  }
  else {
    a.href = window.URL.createObjectURL(blob, {
      type: "text/plain"
    });
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

export const formattedDate = (values) => {
  const payload = values;
  const timeFormat = 'YYYY-MM-DD HH:mm';
  // 把 form datetime picker 轉成需要的 format, antd 沒有幫你做。
  const times = ['start_use_time', 'exchange_expire_time', 'use_expire_time'];
  times.forEach(time => {
    if (payload[time]) {
      const formattedTime = moment(payload[time]).format(timeFormat)
      payload[time] = formattedTime
    }
  })
  console.log(payload)
  return payload
}


export const StringValFilter = (str) => {
  var s = "";
  if (str.length === 0) {
    return "";
  }
  s = str.replace(/&amp;/g, "&");
  s = s.replace(/&lt;/g, "<");
  s = s.replace(/&gt;/g, ">");
  s = s.replace(/&nbsp;/g, " ");
  s = s.replace(/&#39;/g, "\'");
  s = s.replace(/&quot;/g, "\"");
  return s;
}
export const clearLocalStorageByPrefix = (prefix) => {
  _.forIn(window.localStorage, (value, objKey) => {
    if (_.startsWith(objKey, prefix)) {
      window.localStorage.removeItem(objKey);
    }
  });
}
