import {
  queryItems,
  queryItemInfo,
  queryItemsWithId,
  createItem,
  updateItem,
  generateItem,
} from '@/services/notification';
import moment from 'moment';
import { message, notification } from 'antd';
import { connect, useIntl, FormattedMessage, history } from 'umi';
import openNotification from '@/utils/notify';
import { formattedDate } from '@/utils/utils';

const namespace = 'notification'
const AppSettingModel = {
  namespace,
  defaultValue: {
    pushNotifications: [],
  },
  actionType: {
    FETCH_ITEMS: `${namespace}/fetchList`,
    FETCH_LOCATIONS: `${namespace}/fetchLocations`,
    FETCH_ITEM_INFO: `${namespace}/fetch`,
    FETCH_ITEM_DETAIL: `${namespace}/fetchItemDetail`,
    CREATE_ITEM: `${namespace}/create`,
    EDIT_ITEM: `${namespace}/edit`,
    SAVE_ITEM: `${namespace}/save`,
    GEN_ITEM: `${namespace}/generateItem`,
  },
  state: {
    total: 0,
    subTotal: 0,
    locations: []
  },
  effects: {
    *fetchList(_, { call, put }) {
      try {
        const response = yield call(queryItems, _.payload, { method: 'POST' });
        yield put({
          type: 'save', payload: {
            items: response.status.data ? formattedDate(response.status.data) : response.status.data,
            status: { ...response.status, name: 'fetch', total: response.status.total }
          }
        });
        message.success('fetch success');
      } catch (e) {
        console.log(e)
        message.error(`${e}`);
      }
    },
    *fetch(_, { call, put }) {
      try {
        const response = yield call(queryItemInfo, _.payload, { method: 'POST' });
        yield put({
          type: 'save', payload: {
            item: response.status.data ? formattedDate(response.status.data) : response.status.data,
            status: { ...response.status, name: 'fetch', total: response.status.total }
          }
        });
        message.success('fetch success');
      } catch (e) {
        console.log(e)
        message.error(`${e}`);
      }
    },
    *fetchItemDetail(_, { call, put, all }) {
      const { id, offset, limit } = _.payload;
      try {
        const [itemRes, receiverLogRes] = yield all([
          call(queryItemInfo, { id }, { method: 'POST' }),
          call(queryItemsWithId, { data: { id }, offset, limit }, { method: 'POST' })
        ])

        // console.log('itemRes.status.data.send_list')
        // console.log(itemRes.status.data)
        // console.log(JSON.parse(itemRes.status.data.send_list))
        itemRes.status.data.send_list = itemRes.status.data.send_list.length ? JSON.parse(itemRes.status.data.send_list) : itemRes.status.data.send_list.length
        yield put({
          type: 'save', payload: {
            item: itemRes.status.data ? formattedDate(itemRes.status.data) : itemRes.status.data,
            name: 'fetchItemDetail',
            code: receiverLogRes.status.code,
            // serialNumList: receiverLogRes.status.data,
            // serialNumTotal: receiverLogRes.status.total,
            status: {
              ...receiverLogRes.status,
            }
          }
        });
        message.success('fetch success');
      } catch (e) {
        console.log(e)
        message.error(`${e}`);
      }
    },
    *create(_, { call, put }) {
      try {
        const timeFormat = 'YYYY-MM-DD HH:mm';
        console.log('_.payload.data.start_send_time')
        console.log(_.payload.data.start_send_time)
        const loaded = { ..._.payload.data, start_send_time: moment(_.payload.data.start_send_time).subtract(8, 'hours').format(timeFormat) }
        console.log('loaded')
        console.log(loaded.start_send_time)
        const response = yield call(createItem, { ..._.payload, data: loaded }, { method: 'POST' });
        yield put({ type: 'save', payload: { ...response, name: 'create' } });
        openNotification('bottomRight', response.status);
        if (response.status.code === 1) history.push(`/push-${namespace}`);
      } catch (e) {
        console.log(e)
        message.error(`${e}`);
      }
    },
    *edit(_, { call, put }) {
      try {
        const timeFormat = 'YYYY-MM-DD HH:mm';
        console.log('_.payload.data.start_send_time')
        console.log(_.payload.data.start_send_time)
        const loaded = { ..._.payload.data, start_send_time: moment(_.payload.data.start_send_time).subtract(8, 'hours').format(timeFormat) }
        console.log('loaded')
        console.log(loaded.start_send_time)
        const response = yield call(updateItem, { ..._.payload, data: loaded }, { method: 'POST' });

        const { data: item } = _.payload;
        yield put({ type: 'save', payload: { ...response, item, name: 'edit' } });
        openNotification('bottomRight', response.status);
        if (response.status.code === 1) history.push(`/push-${namespace}/${item.id}`);
      } catch (e) {
        console.log(e)
        message.error(`${e}`);
      }
    },
    *generateItem(_, { call, put }) {
      const send_list = JSON.stringify(_.payload.data.send_list)
      const data = { ..._.payload.data, send_list }
      const payload = { ..._.payload, data }
      const response = yield call(generateItem, payload, { method: 'POST' });
      yield put({ type: 'save', payload: response, name: 'generateNotiy' });
    },
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
}

export default AppSettingModel;

// TODO: 推播狀態是什麼
// 0: 尚未開放 1:啟用 2:關閉
export const statusEnum = {
  0: {
    text: '草稿',
    // text: '尚未開啟',
    status: 'Success',
  },
  1: {
    text: '可推播',
    // text: '啟用',
    status: 'Processing',
  },
  2: {
    // text: '關閉',
    text: '已推播',
    status: 'Default',
  },
};

export const modeEnum = {
  1: {
    text: '使用名單發送',
    // status: 'Success',
  },
  2: {
    text: '性別 (male/female)',
    // status: 'Processing',
  },
  3: {
    text: '訂閱/非訂閱',
    // status: 'Processing',
  },
  4: {
    text: '場館',
    // status: 'Processing',
  },
};

export const ITEM_MODE = {
  SEND_BY_LIST: 1,
  SEND_BY_GENDER: 2,
  SEND_BY_SUBSCRIPTION: 3,
  SEND_BY_LOCATION: 4,
}

export const ITEM_STATUS = {
  DRAFT: 0,
  PUBLISHED: 1,
  CLOSED: 2
}

export const ITEM_OPEN = {
  IS_CLOSE: 0,
  IS_OPEN: 1
}

export const GENDER_TYPE = {
  IS_MALE: 'male',
  IS_FEMALE: 'female'
}

export const SUBSCRIBE_TYPE = {
  IS_SUBSCRIBE: 'subscribe',
  IS_UNSUBSCRIBE: 'unsubscribe'
}

export const LOCATION_LIST = [
  { value: '42', key: '42', label: '\u7e3d\u516c\u53f8', name: '\u7e3d\u516c\u53f8' },
  { value: '43', key: '43', label: '\u4fe1\u7fa9\u5b89\u548c', name: '\u4fe1\u7fa9\u5b89\u548c' },
  { value: '44', key: '44', label: '\u5167\u6e56\u745e\u5149', name: '\u5167\u6e56\u745e\u5149' },
  { value: '45', key: '45', label: '\u5fe0\u5b5d\u5e02\u5e9c', name: '\u5fe0\u5b5d\u5e02\u5e9c' },
  { value: '68', key: '68', label: '\u5167\u6e56\u6210\u529f', name: '\u5167\u6e56\u6210\u529f' },
  { value: '91', key: '91', label: '\u4fe1\u7fa9\u677e\u4ec1', name: '\u4fe1\u7fa9\u677e\u4ec1' },
  {
    value: '106',
    key: '106',
    label: 'xarefit\u4eab\u5065\u8eab x JustCo',
    name: 'xarefit\u4eab\u5065\u8eab x JustCo',
  },
  {
    value: '107',
    key: '107',
    label: '\u4eab\u8ab2\u7a0b@\u4ea4\u6613\u5ee3\u5834\u4e8c\u671f',
    name: '\u4eab\u8ab2\u7a0b@\u4ea4\u6613\u5ee3\u5834\u4e8c\u671f',
  },
];

export const MODE_LIST = [
  {
    value: 1,
    label: '根據使用名單做推播',
  },
  {
    value: 2,
    label: '根據性別做推播',
  },
  {
    value: 3,
    label: '根據訂閱/非訂閱做推播',
  },
  {
    value: 4,
    label: '根據場館做推播',
  },
];

export const DEFAULT_LOCATION_ID = '45';
