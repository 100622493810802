import moment from 'moment';

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}
export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;
    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  const year = now.getFullYear();

  if (type === 'month') {
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();
    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
}

export const subscribeDataSchema = {
  subscribingUser: {
    title: '會籍訂閱',
    detail: '訂閱中人數',
    detailTips:
      '會籍狀態為「訂閱中」+「尚未生效」的不重複會員數。尚未生效，指訂閱成功但尚未開始啟用。',
    dataKey: 'use',
  },
  subscribeFail: {
    title: '訂閱失敗',
    detail: '訂閱失敗人數',
    detailTips: '續訂扣款失敗的的不重複會員數。',
    dataKey: 'failed',
  },
  subscribeCancel: {
    title: '取消訂閱',
    detail: '取消訂閱人數',
    detailTips: '取消訂閱會籍的不重複會員數。',
    dataKey: 'cancel',
  },
  subscribeExpired: {
    title: '到期訂閱',
    detail: '到期訂閱人數',
    detailTips: '訂閱會籍結束日，對應該日期(月份)的不重複會員數(來源為取消訂閱和訂閱失敗)。',
    dataKey: 'expired',
  },
  subscribingNewUser: {
    title: '新訂閱',
    detail: '新訂閱人數',
    detailTips: '對應該日期(月份)購買訂閱制會籍的不重覆會員數(來源為首次訂閱和回購訂閱)。',
    dataKey: 'new',
  },
  subscribingFirstUser: {
    title: '首次訂閱',
    detail: '首次訂閱人數',
    detailTips:
      '於該品牌購買的第一筆訂閱制會籍，不區隔會籍類型。舉例：第一筆訂閱團體課會籍(此為首訂)，第二筆訂閱會籍卡會籍(此筆非首訂)。',
    dataKey: 'first',
  },
  subscribingReturnUser: {
    title: '回購訂閱',
    detail: '回購訂閱人數',
    detailTips:
      '查詢區間新訂閱的訂單日期，和上筆所購訂閱制會籍結束日，間距回購區間N天以上(含)的不重複會員數。',
    dataKey: 'return',
  },
};
