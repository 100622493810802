import { queryUser, queryFakeList } from './service';

const Model = {
  namespace: 'accountAndcenter',
  state: {
    currentUser: {},
    list: [],
  },
  effects: {
    *fetchCurrent(_, { call, put }) {
      const response = yield call(queryUser, _.payload);
      console.log(response)
      yield put({
        type: 'saveCurrentUser',
        payload: response.data,
      });
    },

    *fetch({ payload }, { call, put }) {
      const response = yield call(queryFakeList, payload);
      yield put({
        type: 'queryList',
        payload: Array.isArray(response) ? response : [],
      });
    },
  },
  reducers: {
    saveCurrentUser(state, action) {
      return { ...state, currentUser: {
        raw: action.payload,
        data: action.payload
      } || {} };
      // 
    },

    queryList(state, action) {
      return { ...state, list: action.payload };
    },
  },
};
export default Model;
