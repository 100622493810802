import _ from 'lodash';
import request from '@/utils/request';
import { XAREFIT_API_ENDPOINT, method, downloadCSV } from '@/utils/utils';

// 新增 Coupon [後台]
export async function queryCoupons(params) {
  return request(`${XAREFIT_API_ENDPOINT}/coupons/list`, {
    method,
    data: { ...params },
  });
}
// Coupon info  [後台]
export async function queryCouponInfo(params) {
  return request(`${XAREFIT_API_ENDPOINT}/coupons/coupon`, {
    method,
    data: { ...params },
  });
}
// serial number  List [後台]
export async function queryCouponSerialNumList(params) {
  const { data: { coupon_id } } = params;
  return request(`${XAREFIT_API_ENDPOINT}/coupons/list/sno`, {
    method,
    data: { ...params, coupon_id },
  });
}
// generate serial number  [後台] 手動按鈕點擊後產生「序號」
export async function generateSno(params) {
  const { data: { coupon_id } } = params;
  return request(`${XAREFIT_API_ENDPOINT}/coupons/generate`, {
    method,
    data: { ...params, coupon_id },
  });
}
// export async function removeCoupons(params) {
//   const { couponId } = params;
//   return request(`${XAREFIT_API_ENDPOINT}/delete/${couponId}`, {
//     data: { ...params, method: 'delete' },
//   });
// }

// 列出 Coupon  [後台]
export async function addCoupon(params) {
  return request(`${XAREFIT_API_ENDPOINT}/coupons/create`, {
    method,
    data: { ...params },
  });
}
// 新增 Coupon [後台]
export async function updateCoupon(params) {
  const { data: { coupon_id } } = params;
  return request(`${XAREFIT_API_ENDPOINT}/coupons/update`, {
    method,
    data: { ...params, coupon_id },
  });
}

// 新增 Coupon [後台]
export async function downloadCouponSnoSheet(params) {
  const { coupon_id } = params;
  const res = await request(`${XAREFIT_API_ENDPOINT}/coupons/dowanloadSnoSheet`, {
    method,
    data: { ...params, coupon_id },
  });
  downloadCSV(res.filename, res.content);
}

// 查詢教練列表 [後台]
export async function queryCoaches(params) {
  return request(`${XAREFIT_API_ENDPOINT}/coupons/coaches`, {
    method,
    data: { ...params },
  });
}

// 查詢分類列表 [後台]
export async function queryCategories(params) {
  return request(`${XAREFIT_API_ENDPOINT}/coupons/categories`, {
    method,
    data: { ...params },
  });
}

// 查詢優惠卷分類使用列表 [後台]
export async function queryCouponCategories(params) {
  return request(`${XAREFIT_API_ENDPOINT}/coupon/category`, {
    method,
    data: { ...params },
  });
}


// 查詢分類列表 [後台]
export async function queryPackages(params) {
  const { data: { category_id } } = params;
  return request(`${XAREFIT_API_ENDPOINT}/coupons/packages`, {
    method,
    data: { ...params, category_id },
  });
}

// 查詢場館列表 [後台]
export async function queryLocations(params) {
  return request(`${XAREFIT_API_ENDPOINT}/coupons/locations`, {
    method,
    data: { ...params },
  });
}
