// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/usr/app/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/user",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/usr/app/src/layouts/UserLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "name": "login",
        "path": "/user/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__login' */'/usr/app/src/pages/user/login'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'/usr/app/src/layouts/SecurityLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/sites",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SiteLayout' */'/usr/app/src/layouts/SiteLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/sites/:id",
            "name": "sites",
            "icon": "TeamOutlined",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Site' */'/usr/app/src/pages/Site'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/usr/app/src/layouts/BasicLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "redirect": "/push-notification",
            "exact": true
          },
          {
            "path": "/account/center",
            "name": "account-center",
            "icon": "member",
            "hideInMenu": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__center' */'/usr/app/src/pages/account/center'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/dashboard",
            "name": "dashboard",
            "icon": "dashboard",
            "authority": [
              "superAdmin",
              "admin"
            ],
            "routes": [
              {
                "path": "/",
                "redirect": "/dashboard/analysis",
                "exact": true
              },
              {
                "name": "member-classification",
                "authority": [
                  "superAdmin",
                  "admin"
                ],
                "icon": "smile",
                "path": "/dashboard/member-classification",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard__analysis' */'/usr/app/src/pages/Dashboard/analysis'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/members",
            "name": "member",
            "icon": "TeamOutlined",
            "routes": [
              {
                "path": "/",
                "redirect": "/members",
                "exact": true
              },
              {
                "path": "/members",
                "name": "member-list",
                "icon": "member",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MemberList' */'/usr/app/src/pages/MemberList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/members/:id",
                "name": "member-information",
                "icon": "member",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__account__center' */'/usr/app/src/pages/account/center'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/device-management",
            "name": "device-management",
            "icon": "DatabaseOutlined",
            "authority": [
              "superAdmin"
            ],
            "routes": [
              {
                "path": "/",
                "redirect": "/device-list",
                "exact": true
              },
              {
                "path": "/device-management/device-list",
                "name": "device-list",
                "icon": "smile",
                "authority": [
                  "superAdmin"
                ],
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/usr/app/src/pages/Welcome'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/device-management/device-information",
                "name": "device-information",
                "icon": "smile",
                "authority": [
                  "superAdmin"
                ],
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/usr/app/src/pages/Welcome'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/membership",
            "name": "product-all",
            "icon": "DatabaseOutlined",
            "authority": [
              "superAdmin"
            ],
            "routes": [
              {
                "path": "/",
                "redirect": "/membership",
                "exact": true
              },
              {
                "path": "/products",
                "name": "products",
                "icon": "smile",
                "authority": [
                  "superAdmin"
                ],
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/usr/app/src/pages/Welcome'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/membership",
                "name": "membership",
                "icon": "smile",
                "authority": [
                  "superAdmin"
                ],
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/usr/app/src/pages/Welcome'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/courses",
                "name": "courses",
                "icon": "smile",
                "authority": [
                  "superAdmin"
                ],
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/usr/app/src/pages/Welcome'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/events",
                "name": "events",
                "icon": "smile",
                "authority": [
                  "superAdmin"
                ],
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/usr/app/src/pages/Welcome'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/push-notification",
            "name": "push-notification",
            "icon": "DatabaseOutlined",
            "authority": [
              "superAdmin",
              "admin"
            ],
            "routes": [
              {
                "path": "/",
                "redirect": "/push-notification",
                "exact": true
              },
              {
                "path": "/lightbox",
                "name": "lightbox",
                "icon": "smile",
                "authority": [
                  "superAdmin"
                ],
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/usr/app/src/pages/Welcome'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/push-notification",
                "name": "push-notification",
                "icon": "smile",
                "authority": [
                  "superAdmin",
                  "admin"
                ],
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PushNotification' */'/usr/app/src/pages/PushNotification'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/push-notification/create",
                "name": "push-notification-create",
                "icon": "smile",
                "authority": [
                  "superAdmin",
                  "admin"
                ],
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PushNotification__create' */'/usr/app/src/pages/PushNotification/create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/push-notification/:id",
                "name": "push-notification-with-id",
                "icon": "smile",
                "authority": [
                  "superAdmin",
                  "admin"
                ],
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PushNotification__detail' */'/usr/app/src/pages/PushNotification/detail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/push-notification/:id/edit",
                "name": "push-notification-edit",
                "icon": "smile",
                "authority": [
                  "superAdmin",
                  "admin"
                ],
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__PushNotification__edit' */'/usr/app/src/pages/PushNotification/edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/coupons",
            "name": "coupons",
            "icon": "ContainerOutlined",
            "authority": [
              "superAdmin",
              "admin"
            ],
            "routes": [
              {
                "path": "/",
                "redirect": "/coupons",
                "exact": true
              },
              {
                "path": "/coupons",
                "name": "coupon-list",
                "icon": "smile",
                "authority": [
                  "superAdmin",
                  "admin"
                ],
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CouponList' */'/usr/app/src/pages/CouponList'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/coupons/create",
                "name": "coupon-create",
                "icon": "smile",
                "authority": [
                  "superAdmin",
                  "admin"
                ],
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CouponList__create' */'/usr/app/src/pages/CouponList/create'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/coupons/:id",
                "name": "coupon-detail",
                "icon": "smile",
                "authority": [
                  "superAdmin",
                  "admin"
                ],
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CouponList__CouponDetail' */'/usr/app/src/pages/CouponList/CouponDetail'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/coupons/:id/edit",
                "name": "coupon-edit",
                "icon": "smile",
                "authority": [
                  "superAdmin",
                  "admin"
                ],
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CouponList__edit' */'/usr/app/src/pages/CouponList/edit'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/usr/app/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/usr/app/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/usr/app/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
