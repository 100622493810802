// @ts-nocheck

import TeamOutlined from '@ant-design/icons/TeamOutlined';
import DashboardOutlined from '@ant-design/icons/DashboardOutlined';
import SmileOutlined from '@ant-design/icons/SmileOutlined';
import DatabaseOutlined from '@ant-design/icons/DatabaseOutlined';
import ContainerOutlined from '@ant-design/icons/ContainerOutlined'

export default {
  TeamOutlined,
DashboardOutlined,
SmileOutlined,
DatabaseOutlined,
ContainerOutlined
}
    