import { Card, message, Space, notification, Alert } from 'antd';

const openNotification = (placement, status) => {
  notification[status.code === 1 ? 'success' : 'error']({
    message: status.code === 1 ? `Success` : 'Fail',
    description: `${status.reason}`,
    placement,
  });
};

export default openNotification;