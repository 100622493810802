import {
  queryCouponInfo,
  queryCoaches,
  queryCategories,
  queryCouponCategories,
  queryPackages,
  queryLocations,
  queryCouponSerialNumList,
  generateSno,
  addCoupon,
  updateCoupon,
  downloadCouponSnoSheet
} from '@/services/coupon';
import moment from 'moment';
import { message, notification } from 'antd';
import { connect, useIntl, FormattedMessage, history } from 'umi';
import openNotification from '@/utils/notify';
import browserLocalstorage from 'browser-localstorage-expire';
import { unionBy, find } from 'lodash';

const localCache = browserLocalstorage();

const formattedDate = (values) => {
  const payload = values;
  const timeFormat = 'YYYY-MM-DD HH:mm';
  // 把 form datetime picker 轉成需要的 format, antd 沒有幫你做。
  const times = ['start_use_time', 'exchange_expire_time', 'use_expire_time'];
  times.forEach(time => {
    if (payload[time]) {
      const formattedTime = moment(payload[time]).format(timeFormat)
      payload[time] = formattedTime
    }
  })
  console.log(payload)
  return payload
}

const CouponModel = {
  actionType: {
    ADD_SERIAL_NUM_LIST: 'coupon/addSerialNumList',
    FETCH_SERIAL_NUM_LIST: 'coupon/fetchSerialNumList',
    FETCH_COUPON_DETAIL: 'coupon/fetchCouponDetail',
    DOWNLOAD_SNO_SHEET: 'coupon/downloadSnoSheet',
    EDIT: 'coupon/edit',
    FETCH_WITH_COUPON: 'coupon/fetch',
    SAVE_COUPON: 'coupon/save',
    FETCH_COACH: 'coupon/fetchCoaches',
    FETCH_CATEGORIES: 'coupon/fetchCategories',
    FETCH_PACKAGES: 'coupon/fetchPackages',
    FETCH_LOCATIONS: 'coupon/fetchLocations',
    CREATE_SERIAL_NUM: 'coupon/createSerialNum',
    UPDATE_PACKAGES: 'coupon/updatePackages',
    FETCH_AND_UPDATE_PACKAGES: 'coupon/fetchAndUpdatePackages',
    SAVE_ADN_UPDATE_PACKAGEPATH: 'coupon/saveAndUpdatePackagePath',
    FETCH_CATEGORIES_AND_COACHES: 'coupon/fetchCategoriesAndCoaches',
    FETCH_AND_UPDATE_PACKAGES_BY_CATES: 'coupon/fetchAndUpdatePackagesByCates',
    RESET_COUPON_INFO: 'coupon/resetCouponInfo',
    FETCH_COUPON_CATEGORIES: 'coupon/fetchCouponCategories',
    RESET: 'coupon/reset',
  },
  defaultValue: {
    sno_type: 0,
    sno: 'DOINGTEST1',
    discount_type: 1,
    discount: 10,
    limit: 10,
    title: '預設用優惠卷 標題',
    title_en: 'default coupon en title',
    description: '預設用優惠卷 描述',
    description_en: 'default coupon ',
    category: 0,
    // json backend 是吃 string
    packages: '', // v
    coach_id: 0,
    // 預設享健身全部承擔
    xarefit_share: 100,
    coach_share: 0,
    status: 0, // v
    set_expire_type: 2,
    set_expire_day: 2,
    exchange_expire_time: '2021-06-10 13:01',
    use_expire_time: '2021-06-10 13:00',
    locations: []
  },
  namespace: 'coupon',
  state: {
    coupon: undefined,
    coupons: [],
    total: 0,
    serialNumTotal: 0,
    status: undefined,
    isPublished: false,
    serialNumList: undefined,
    name: '',
    // 教練 id 輸入為字串，取得為陣列
    coaches: [],
    // 陣列
    categories: [],
    packages: [],
    packageList: [],
    packagePath: '',
    packagePathFromCategory: [],
    couponCategories: [],
    isSendingRequest: false,
  },
  effects: {
    // fetch all coupon
    *fetch(_, { call, put, all }) {
      try {
        let packageList = [];
        // 取得優惠卷資訊
        const response = yield call(queryCouponInfo, _.payload, { method: 'POST' });
        // 根據路徑取得列表
        if (typeof response.status.data.package_path !== "undefined" && response.status.data.package_path) {
          // 根據 coupon 的方案路徑有就從快取，沒有就去一一拿取對應的列表並儲存
          // package_path may array or string
          const package_path = Array.isArray(response.status.data.package_path) ? response.status.data.package_path : JSON.parse(response.status.data.package_path);
          packageList = yield all(package_path.map(path => call(function* () {
            const name = `cateList-${path.category}`
            let packList = localStorage.getItem(name)
            if (!packList) {
              const responseFromCate = yield call(queryPackages, { data: { category_id: path.category } }, { method: 'POST' });
              packList = responseFromCate.status.data;
              if (name) localCache.setItem(name, packList, 1)
            } else {
              packList = JSON.parse(packList)
            }
            // console.log(packList)
            // console.log('packList1')
            // console.log(path.package_no)
            // console.log('packList2')
            // console.log(JSON.parse(packList.value))
            // const packListArr = (packList.value.length) ? packList.value : JSON.parse(packList.value)
            // 將選到的分類，根據 coupon 之前的來確認選到的方案
            const selectedPackage = find(packList.value, p => p.package_no === path.package_no)
            return selectedPackage
          })))
          console.log('packageListpackageListpackageList')
          console.log(packageList)
        }

        yield put({
          type: 'save', payload: {
            coupon: response.status.data ? formattedDate(response.status.data) : response.status.data,
            status: { ...response.status, name: 'fetch', total: response.status.total },
            packageList,
            packages: response.status.data.packages,
            packagePath: response.status.data.package_path,
          }
        });
        message.success('fetch success');
      } catch (e) {
        console.log(e)
        message.error(`${e}`);
      }
    },
    *fetchCategoriesAndCoaches(_, { all, call, put }) {
      function* fetchCategories() {
        return yield call(queryCategories, _.payload, { method: 'POST' })
      }
      function* fetchCoaches() {
        return yield call(queryCoaches, _.payload, { method: 'POST' })
      }
      const [categoriesRes, coachesRes] = yield all([call(fetchCategories), call(fetchCoaches)])
      const categories = categoriesRes.status.data ? formattedDate(categoriesRes.status.data) : categoriesRes.status.data
      const coaches = coachesRes.status.data ? formattedDate(coachesRes.status.data) : coachesRes.status.data
      console.log([categories, coaches])
      yield put({ type: 'save', payload: { categories, coaches } })
    },
    *fetchCoaches(_, { call, put }) {
      try {
        const response = yield call(queryCoaches, _.payload, { method: 'POST' });
        yield put({
          type: 'save', payload: {
            coaches: response.status.data ? formattedDate(response.status.data) : response.status.data,
            status: { ...response.status, name: 'fetch', total: response.status.total }
          }
        });
        // localStorage.setItem('coaches', JSON.stringify(response.status.data))
        if (response.status.data) localCache.setItem('coaches', response.status.data, 1);
        message.success('fetch success');
      } catch (e) {
        console.log(e)
        message.error(`${e}`);
      }
    },
    *fetchCategories(_, { call, put }) {
      try {
        const cached = localCache.getItem('categories')
        if (cached) {
          yield put({
            type: 'save', payload: {
              categories: cached,
              status: 'success'
            }
          })
        } else {
          const response = yield call(queryCategories, _.payload, { method: 'POST' });
          yield put({
            type: 'save', payload: {
              categories: response.status.data ? formattedDate(response.status.data) : response.status.data,
              status: { ...response.status, name: 'fetch', total: response.status.total }
            }
          });
          if (response.status.data) localCache.setItem('categories', response.status.data, 1);
        }
        message.success('fetch success');
      } catch (e) {
        console.log(e)
        message.error(`${e}`);
      }
    },
    *fetchCouponCategories(_, { call, put }) {
      const cachedKey = 'coupon_categories'
      try {
        const cached = localCache.getItem(cachedKey)
        if (cached) {
          yield put({
            type: 'save', payload: {
              categories: cached,
              status: 'success'
            }
          })
        } else {
          const response = yield call(queryCouponCategories, _.payload, { method: 'POST' });
          yield put({
            type: 'save', payload: {
              couponCategories: response.status.data ? formattedDate(response.status.data) : response.status.data,
              status: { ...response.status, name: 'fetch', total: response.status.total }
            }
          });
          if (cachedKey && response.status.data) localCache.setItem(cachedKey, response.status.data, 1);
        }
        message.success('fetch success');
      } catch (e) {
        console.log(e)
        message.error(`${e}`);
      }
    },
    *fetchPackages(_, { call, put }) {
      let payload;
      try {
        const response = yield call(queryPackages, _.payload, { method: 'POST' });
        payload = {
          packages: response.status.data ? formattedDate(response.status.data) : response.status.data,
          status: { ...response.status, name: 'fetch', total: response.status.total }
        }
        yield put({
          type: 'save', payload
        });
        message.success('fetch success');
      } catch (e) {
        console.log(e)
        message.error(`${e}`);
      }
      return payload || []
    },
    *fetchLocations(_, { call, put }) {
      let payload;
      try {
        const response = yield call(queryLocations, _.payload, { method: 'POST' });
        console.log('response.status.data')
        console.log(response.status.data)
        payload = {
          locations: response.status.data ? formattedDate(response.status.data) : response.status.data,
          status: { ...response.status, name: 'fetch', total: response.status.total }
        }
        yield put({
          type: 'save', payload
        });
        message.success('fetch success');
      } catch (e) {
        console.log(e)
        message.error(`${e}`);
      }
      return payload || []
    },
    *fetchAndUpdatePackagesByCates(_, { all, call, put }) {
      // fetchAndUpdatePackages()
      console.log('fetchAndUpdatePackagesByCates')
      console.log(_.payload)
      const items = _.payload ? [_.payload] : [];
      const fetchAll = [];

      items.forEach((cateId) => {
        fetchAll.push(call(function* () {
          console.log(cateId)
          let payload = {};
          const name = `cateList-${cateId}`
          const packageCache = localStorage.getItem(name)
          console.log(`name ${name}`)
          payload.categoryId = cateId
          payload.packages = JSON.parse(packageCache)
          if (!packageCache) {
            const response = yield call(queryPackages, { data: { category_id: cateId } }, { method: 'POST' });
            console.log(response)
            payload.packages = response.status.data ? formattedDate(response.status.data) : response.status.data
            payload.status = { ...response.status, name: 'fetch', total: response.status.total }
            localStorage.setItem(name, JSON.stringify(payload.packages))
            message.success('fetch success');
          }
          return payload
        }))
      })
      const payloadsByCategory = yield all(fetchAll);
      console.log('!!payload')
      console.log(payloadsByCategory)
      const packageList = payloadsByCategory.reduce((final, item) => unionBy(item.packages, final.packageList, "package_no"), { packageList: [] }) || []
      console.log(packageList)
      const packagePathFromCategory = payloadsByCategory.map(({ categoryId, packages }) => ({ category: categoryId, packages }))
      console.log(packagePathFromCategory)
      yield put({
        type: 'save',
        payload: { packageList, packagePathFromCategory }
      });
    },
    *fetchPackagesddd(_, { all, call, put }) {
      // fetchAndUpdatePackages()
      console.log('fetchAndUpdatePackagesByCates')
      console.log(_.payload)
      const items = _.payload || [];
      const fetchAll = [];

      items.forEach((cateId) => {
        fetchAll.push(call(function* () {
          console.log(cateId)
          let payload = {};
          const name = `cateList-${cateId}`
          const packageCache = localStorage.getItem(name)
          console.log(`name ${name}`)
          payload.categoryId = cateId
          payload.packages = JSON.parse(packageCache)
          if (!packageCache) {
            const response = yield call(queryPackages, { data: { category_id: cateId } }, { method: 'POST' });
            console.log(response)
            payload.packages = response.status.data ? formattedDate(response.status.data) : response.status.data
            payload.status = { ...response.status, name: 'fetch', total: response.status.total }
            localStorage.setItem(name, JSON.stringify(payload.packages))
            message.success('fetch success');
          }
          return payload
        }))
      })
      const payloadsByCategory = yield all(fetchAll);
      console.log('!!payload')
      console.log(payloadsByCategory)
      const packageList = payloadsByCategory.reduce((final, item) => unionBy(item.packages, final.packageList, "package_no"), { packageList: [] }) || []
      console.log(packageList)
      const packagePathFromCategory = payloadsByCategory.map(({ categoryId, packages }) => ({ category: categoryId, packages }))
      console.log(packagePathFromCategory)
      yield put({
        type: 'save',
        payload: { packageList, packagePathFromCategory }
      });
    },
    *fetchAndUpdatePackages(_, { call, put }) {
      let payload;
      if (!_.payload.data.category_id) {
        yield put({ type: 'resetPackageList' });
      }
      try {
        const name = `cateList-${_.payload.data.category_id}`
        const packageCache = localStorage.getItem(name)
        console.log(`name ${name}`)
        if (!packageCache) {
          const response = yield call(queryPackages, _.payload, { method: 'POST' });
          console.log(response)
          payload = {
            packages: response.status.data ? formattedDate(response.status.data) : response.status.data,
            status: { ...response.status, name: 'fetch', total: response.status.total },
            categoryId: _.payload.data.category_id
          }
          localStorage.setItem(name, JSON.stringify(payload.packages))

          yield put({
            type: 'saveAndUpdate', payload
          });
          message.success('fetch success');
        } else {
          yield put({
            type: 'saveAndUpdate', payload: {
              packages: JSON.parse(packageCache),
              categoryId: _.payload.data.category_id
            }
          });
        }
      } catch (e) {
        console.log(e)
        message.error(`${e}`);
      }
      return payload || []
    },
    *fetchCouponDetail(_, { call, put, all }) {
      const { id, offset, limit } = _.payload;
      try {
        const [
          couponsRes,
          serialsNumRes,
          coachRes,
          categoriesRes,
          packageRes,
        ] = yield all([
          call(queryCouponInfo, { id }, { method: 'POST' }),
          call(queryCouponSerialNumList, { data: { coupon_id: id }, offset, limit }, { method: 'POST' }),
          call(queryCoaches, { data: {}, offset, limit }, { method: 'POST' }),
          call(queryCategories, { data: {}, offset, limit }, { method: 'POST' }),
          call(queryPackages, { data: { category_id: id }, offset, limit }, { method: 'POST' }),
        ])
        yield put({
          type: 'save', payload: {
            coupon: couponsRes.status.data ? formattedDate(couponsRes.status.data) : couponsRes.status.data,
            name: 'fetchCouponDetail',
            code: serialsNumRes.status.code,
            serialNumList: serialsNumRes.status.data,
            serialNumTotal: serialsNumRes.status.total,
            coaches: coachRes.status.data,
            categories: categoriesRes.status.data,
            packages: packageRes.status.data,
            status: {
              ...serialsNumRes.status,
            }
          }
        });
        message.success('fetch success');
      } catch (e) {
        console.log(e)
        message.error(`${e}`);
      }
    },
    *downloadSnoSheet(_, { call, put }) {
      try {
        const couponRes = yield call(downloadCouponSnoSheet, _.payload);
        console.log(couponRes)
        // yield put({
        //   type: 'save', payload: {
        //     coupon: couponsRes.status.data ? formattedDate(couponsRes.status.data) : couponsRes.status.data,
        //     name: 'fetchCouponDetail',
        //     code: serialsNumRes.status.code,
        //     serialNumList: serialsNumRes.status.data,
        //     serialNumTotal: serialsNumRes.status.total,
        //     status: {
        //       ...serialsNumRes.status,
        //     }
        //   }
        // });
        message.success('fetch success');
      } catch (e) {
        console.log(e)
        message.error(`${e}`);
      }
    },
    *createAndPublish(_, { call, put }) {
      try {
        const { limit } = _.payload;
        const couponRes = yield call(addCoupon, _.payload, { method: 'POST' });
        if (couponRes.status.code === 1) {
          // TODO: 連續序號因 API 尚未回傳 id 因此無法直接建立連續序號。
          const { coupon_id } = couponRes.coupon;
          const data = {
            coupon_id,
            limit
          };
          const snoRes = yield call(generateSno, { data }, { method: 'POST' });
          yield put({
            type: 'save',
            payload: {
              name: 'createAndPublish',
              coupon: couponRes.coupon,
              serialNumList: snoRes.status.data,
              serialNumTotal: snoRes.status.total,
            }
          });
          // openNotification('bottomRight', couponRes.status);
          if (snoRes.status.code === 1) history.push('/coupons');
        } else {
          console.log(couponRes.status.reason)
          message.error(`${couponRes.status.reason}`);
        }
      } catch (e) {
        console.log(e)
        message.error(`${e}`);
      }
    },
    *create(_, { call, put }) {
      try {
        yield put({ type: 'save', payload: { isSendingRequest: true } });
        const response = yield call(addCoupon, _.payload, { method: 'POST' });
        yield put({ type: 'save', payload: { ...response, name: 'create', isSendingRequest: false } });
        openNotification('bottomRight', response.status);
        if (response.status.code === 1) history.push('/coupons');
      } catch (e) {
        console.log(e)
        message.error(`${e}`);
      }
    },
    *edit(_, { call, put }) {
      // console.log('editediteditedit')
      try {
        const response = yield call(updateCoupon, _.payload, { method: 'POST' });
        const { data: coupon } = _.payload;
        console.log('coupon')
        console.log(coupon)
        if (typeof coupon.packages === 'undefined') coupon.packages = []
        yield put({
          type: 'save',
          payload: {
            ...response,
            coupon,
            packages: Array.isArray(coupon.packages) ? coupon.packages : coupon.packages.split(','),
            packagePath: coupon.packagePath,
            name: 'edit'
          }
        });
        // yield put({ type: 'saveAndUpdatePackagePath', payload: coupon.packages });
        console.log('response')
        console.log(response)
        console.log(response.status)
        openNotification('bottomRight', response.status);
        if (response.status.code === 1) history.push(`/coupons/${coupon.coupon_id}`);
      } catch (e) {
        console.log(e)
        message.error(`${e}`);
      }
    },
    *fetchSerialNumList(_, { call, put }) {
      const response = yield call(queryCouponSerialNumList, _.payload, { method: 'POST' });
      // 取得 response 修改 redux store
      yield put({
        type: 'save',
        payload: {
          ...response.status,
          name: 'fetchSerialNumList',
          code: response.status.code,
          serialNumList: response.status.data,
          serialNumTotal: response.status.total
        }
      });
    },
    *addSerialNumList(_, { call, put }) {
      const { coupon_id, limit } = _.payload.data;
      const data = {
        coupon_id,
        limit
      };
      const response = yield call(generateSno, { data }, { method: 'POST' });
      yield put({ type: 'save', payload: response, name: 'addSerialNumList' });
    },
    *createSerialNum(_, { call, put }) {
      const response = yield call(generateSno, _.payload, { method: 'POST' });
      yield put({ type: 'save', payload: response, name: 'createSerialNum' });
    },
  },
  reducers: {
    reset(state, { payload }) {
      return {
        ...state,
        // coupon: undefined,
        // coaches: [],
        // categories: [],
        // packages: [],
        // packageList: [],
        // packagePathFromCategory: [],
        // couponCategories: [],
      }
    },
    saveCategoriesAndCoaches(state, { payload }) {
      const { categories, coaches } = payload;
      return { ...state, categories, coaches }
    },
    resetCouponInfo(state, action) {
      return { ...state, coaches: [], packageList: [], packagePath: '' }
    },
    resetPackageList(state, action) {
      return { ...state, packageList: [] }
    },
    saveAndUpdatePackagePath(state, { payload }) {
      console.log('saveAndUpdatePackagePath')
      const items = payload
      console.log(items)

      const packagePath = items.map(selectedPackageNo => {
        // 透過 已選擇的 package no 將之前儲存的 已選擇 category 遞迴檢查是否存在，如存在就是他
        const categories = state.packagePathFromCategory.filter(pack => {
          // 檢查已選擇的分類裡 packages 是否有對應的 selectedPackageNo
          return find(pack.packages, function (p) { return p.package_no === selectedPackageNo; });
          // return pack.packages.indexOf(selectedPackageNo) !== -1
        })
        console.log(categories)
        return {
          category: categories.length && categories[0].category,
          package_no: selectedPackageNo
        }
      })
      // const packagePath = items.map(package_no => ({ category: category_id, package_no }))
      // console.log('payload')
      console.log(packagePath)
      return {
        ...state,
        packages: Array.isArray(items) ? items : items.split(','),
        packagePath
      }
    },
    saveAndUpdate(state, { payload }) {
      console.log('saveAndUpdate')
      console.log('now')
      console.log([...state.packageList])
      console.log('new')
      console.log([...payload.packages])

      // console.log([...state.packageList, ...payload.packages])
      return {
        ...state,
        packageList: [...payload.packages],
        packagePath: [...state.packagePath, { category: payload.categoryId, packages: payload.packages }]
      }
    },
    updatePackages(state, { payload }) {
      console.log('payload')
      // console.log(state)
      console.log(payload)
      // console.log(JSON.parse(payload))
      return { ...state, packages: payload }
    },
    saveCoupon(state, coupon) {
      return { ...state, coupon }
    },
    save(state, { payload }) {
      return { ...state, ...payload };
    },
    clear(state) {
      console.log(state)
      return { ...state, coupon: {} };
    }
  },
};
export default CouponModel;

export const SNO_TYPE = {
  FIXED_NO: 0,
  NOT_FIXED_NO: 1
}

export const COUPON_STATUS = {
  DRAFT: 0,
  PUBLISHED: 1,
  CLOSED: 2
}

export const EXPIRE_TYPE = {
  SET_BY_PERIOD: 1,
  SET_BY_DAYS: 2
}

export const DISCOUNT_TYPE = {
  BY_PERCENT: 1,
  BY_MONEY: 0
}
