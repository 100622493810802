
import request from '@/utils/request';
import { XAREFIT_API_ENDPOINT, method } from '@/utils/utils';

export async function queryItems(params) {
  return request(`${XAREFIT_API_ENDPOINT}/notifications/list`, {
    method,
    data: { ...params },
  });
}

export async function queryItemInfo(params) {
  return request(`${XAREFIT_API_ENDPOINT}/notifications/notification`, {
    method,
    data: { ...params },
  });
}

export async function queryItemsWithId(params) {
  const { data: { id } } = params;
  return request(`${XAREFIT_API_ENDPOINT}/notifications/listWithId`, {
    method,
    data: { ...params, id },
  });
}

export async function createItem(params) {
  return request(`${XAREFIT_API_ENDPOINT}/notifications/create`, {
    method: 'POST',
    data: { ...params }
  })
}

export async function updateItem(params) {
  const { data: { id } } = params;
  return request(`${XAREFIT_API_ENDPOINT}/notifications/update`, {
    method,
    data: { ...params, id },
  });
}

export async function generateItem(params) {
  const { data: { id } } = params;
  return request(`${XAREFIT_API_ENDPOINT}/notifications/generate`, {
    method,
    data: { ...params, id },
  });
}