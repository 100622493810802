import request from '@/utils/request';
import { XAREFIT_API_ENDPOINT, method } from '@/utils/utils';

export async function query() {
  return request('/api/users');
}

export async function queryCurrent(params) {
  return request(`${XAREFIT_API_ENDPOINT}/currentUser`, {
    method,
    data: { ...params },
  });
}

export async function queryNotices() {
  return request('/api/notices');
}
