import { fakeChartData, realChartData } from './service';
import { subscribeDataSchema } from './utils';

const initState = {
  visitData: [],
  visitData2: [],
  salesData: [],
  searchData: [],
  offlineData: [],
  offlineChartData: [],
  salesTypeData: [],
  salesTypeDataOnline: [],
  salesTypeDataOffline: [],
  radarData: [],
  subscribeData: [],

  numberalDataFromFitbutler: [],
};
const Model = {
  namespace: 'dashboardAndanalysis',
  state: initState,
  effects: {
    *fetch(_, { call, put }) {
      // const responseReal = yield call(realChartData);
      // console.log('responseReal')
      // console.log(responseReal)
      const response = {
        subscribeData: {
          error: 0,
          data: {
            '2020-01': {
              use: 1432,
              cancel: 41,
              failed: 10,
              date: '2020-01',
              use2: 1432,
              new: 608,
              first: 602,
              return: 2,
              expired: 48,
              male: { 0: 19, 20: 133, 25: 432, 35: 138, 45: 26, 55: 13 },
              female: { 0: 16, 20: 104, 25: 395, 35: 118, 45: 27, 55: 11 },
            },
            '2020-02': {
              use: 1825,
              cancel: 108,
              failed: 13,
              date: '2020-02',
              use2: 1825,
              new: 437,
              first: 428,
              return: 4,
              expired: 88,
              male: { 0: 24, 20: 173, 25: 541, 35: 162, 45: 31, 55: 15 },
              female: { 0: 19, 20: 157, 25: 502, 35: 150, 45: 37, 55: 14 },
            },
            '2020-03': {
              use: 1914,
              cancel: 169,
              failed: 20,
              date: '2020-03',
              use2: 1914,
              new: 176,
              first: 173,
              return: 0,
              expired: 167,
              male: { 0: 26, 20: 187, 25: 573, 35: 164, 45: 30, 55: 12 },
              female: { 0: 21, 20: 168, 25: 527, 35: 152, 45: 39, 55: 15 },
            },
            '2020-04': {
              use: 1946,
              cancel: 178,
              failed: 31,
              date: '2020-04',
              use2: 1946,
              new: 198,
              first: 195,
              return: 1,
              expired: 181,
              male: { 0: 28, 20: 208, 25: 595, 35: 159, 45: 31, 55: 8 },
              female: { 0: 20, 20: 183, 25: 517, 35: 147, 45: 37, 55: 13 },
            },
            '2020-05': {
              use: 2189,
              cancel: 117,
              failed: 78,
              date: '2020-05',
              use2: 2189,
              new: 428,
              first: 415,
              return: 1,
              expired: 154,
              male: { 0: 33, 20: 259, 25: 666, 35: 193, 45: 35, 55: 15 },
              female: { 0: 25, 20: 214, 25: 552, 35: 148, 45: 33, 55: 16 },
            },
            '2020-06': {
              use: 2465,
              cancel: 261,
              failed: 69,
              date: '2020-06',
              use2: 2465,
              new: 425,
              first: 409,
              return: 3,
              expired: 220,
              male: { 0: 58, 20: 278, 25: 776, 35: 215, 45: 38, 55: 18 },
              female: { 0: 29, 20: 249, 25: 598, 35: 155, 45: 35, 55: 16 },
            },
            '2020-07': {
              use: 2742,
              cancel: 171,
              failed: 85,
              date: '2020-07',
              use2: 2742,
              new: 495,
              first: 481,
              return: 1,
              expired: 223,
              male: { 0: 84, 20: 341, 25: 869, 35: 230, 45: 38, 55: 20 },
              female: { 0: 31, 20: 293, 25: 616, 35: 164, 45: 37, 55: 19 },
            },
            '2020-08': {
              use: 3183,
              cancel: 185,
              failed: 84,
              date: '2020-08',
              use2: 3183,
              new: 667,
              first: 657,
              return: 0,
              expired: 198,
              male: { 0: 114, 20: 397, 25: 1008, 35: 283, 45: 45, 55: 23 },
              female: { 0: 33, 20: 339, 25: 702, 35: 176, 45: 42, 55: 21 },
            },
            '2020-09': {
              use: 4052,
              cancel: 246,
              failed: 97,
              date: '2020-09',
              use2: 4052,
              new: 1062,
              first: 1050,
              return: 0,
              expired: 252,
              male: { 0: 138, 20: 480, 25: 1260, 35: 352, 45: 70, 55: 27 },
              female: { 0: 47, 20: 435, 25: 928, 35: 240, 45: 49, 55: 26 },
            },
            '2020-10': {
              use: 4891,
              cancel: 237,
              failed: 114,
              date: '2020-10',
              use2: 4891,
              new: 1088,
              first: 1070,
              return: 1,
              expired: 257,
              male: { 0: 152, 20: 556, 25: 1472, 35: 431, 45: 84, 55: 32 },
              female: { 0: 63, 20: 535, 25: 1166, 35: 305, 45: 62, 55: 33 },
            },
            '2020-11': {
              use: 5258,
              cancel: 285,
              failed: 160,
              date: '2020-11',
              use2: 5258,
              new: 622,
              first: 608,
              return: 1,
              expired: 325,
              male: { 0: 163, 20: 587, 25: 1593, 35: 464, 45: 96, 55: 33 },
              female: { 0: 69, 20: 573, 25: 1254, 35: 324, 45: 67, 55: 35 },
            },
            '2020-12': {
              use: 5398,
              cancel: 256,
              failed: 114,
              date: '2020-12',
              use2: 5400,
              new: 448,
              first: 441,
              return: 0,
              expired: 761,
              male: { 0: 169, 20: 598, 25: 1651, 35: 485, 45: 95, 55: 34 },
              female: { 0: 73, 20: 574, 25: 1274, 35: 337, 45: 73, 55: 37 },
            },
          },
          message: 'success',
        },
      };
      const descList = Object.keys(subscribeDataSchema);
      descList.map((desc) => {
        const model = subscribeDataSchema[desc];
        model.data = [];
        Object.keys(response.subscribeData.data).map((key, idx) => {
          const val = response.subscribeData.data[key][model.dataKey];
          model.data.push({ x: key, y: val });
          model.total = val;
        });
      });

      // visitData = []

      yield put({
        type: 'save',
        payload: { ...response, salesData: subscribeDataSchema },
      });
    },

    *fetchSalesData(_, { call, put }) {
      const response = yield call(realChartData);
      console.log('fetchSalesData');
      console.log(response);
      yield put({
        type: 'save',
        payload: {
          salesData: response.subscribeData,
        },
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },

    clear() {
      return initState;
    },
  },
};
export default Model;
