// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/usr/app/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import ModelAppsetting0 from '/usr/app/src/models/appsetting.js';
import ModelCoupon1 from '/usr/app/src/models/coupon.js';
import ModelGlobal2 from '/usr/app/src/models/global.js';
import ModelLogin3 from '/usr/app/src/models/login.js';
import ModelMember4 from '/usr/app/src/models/member.js';
import ModelNotification5 from '/usr/app/src/models/notification.js';
import ModelSetting6 from '/usr/app/src/models/setting.js';
import ModelUser7 from '/usr/app/src/models/user.js';
import ModelModel8 from '/usr/app/src/pages/account/center/components/advanced-form/model.js';
import ModelModel9 from '/usr/app/src/pages/account/center/model.js';
import ModelModel10 from '/usr/app/src/pages/account/settings/model.js';
import ModelModel11 from '/usr/app/src/pages/Dashboard/analysis/model.js';
import ModelModel12 from '/usr/app/src/pages/Dashboard/monitor/model.js';
import ModelModel13 from '/usr/app/src/pages/Dashboard/workplace/model.js';
import ModelModel14 from '/usr/app/src/pages/list/basic-list/model.js';
import ModelModel15 from '/usr/app/src/pages/list/card-list/model.js';
import ModelModel16 from '/usr/app/src/pages/list/search/applications/model.js';
import ModelModel17 from '/usr/app/src/pages/list/search/articles/model.js';
import ModelModel18 from '/usr/app/src/pages/list/search/projects/model.js';
import ModelModel19 from '/usr/app/src/pages/profile/advanced/model.js';
import ModelModel20 from '/usr/app/src/pages/profile/basic/model.js';
import ModelModel21 from '/usr/app/src/pages/Site/model.js';

let app:any = null;

export function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'appsetting', ...ModelAppsetting0 });
app.model({ namespace: 'coupon', ...ModelCoupon1 });
app.model({ namespace: 'global', ...ModelGlobal2 });
app.model({ namespace: 'login', ...ModelLogin3 });
app.model({ namespace: 'member', ...ModelMember4 });
app.model({ namespace: 'notification', ...ModelNotification5 });
app.model({ namespace: 'setting', ...ModelSetting6 });
app.model({ namespace: 'user', ...ModelUser7 });
app.model({ namespace: 'model', ...ModelModel8 });
app.model({ namespace: 'model', ...ModelModel9 });
app.model({ namespace: 'model', ...ModelModel10 });
app.model({ namespace: 'model', ...ModelModel11 });
app.model({ namespace: 'model', ...ModelModel12 });
app.model({ namespace: 'model', ...ModelModel13 });
app.model({ namespace: 'model', ...ModelModel14 });
app.model({ namespace: 'model', ...ModelModel15 });
app.model({ namespace: 'model', ...ModelModel16 });
app.model({ namespace: 'model', ...ModelModel17 });
app.model({ namespace: 'model', ...ModelModel18 });
app.model({ namespace: 'model', ...ModelModel19 });
app.model({ namespace: 'model', ...ModelModel20 });
app.model({ namespace: 'model', ...ModelModel21 });
  return app;
}

export function getApp() {
  return app;
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (typeof window !== 'undefined') {
      _onCreate();
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    const app = getApp();
    app.router(() => this.props.children);
    return app.start()();
  }
}
