import request from '@/utils/request';

export async function query({ sorter, filter, ...params }) {
  const { current, ...queryParams } = params;
  let queryString = '';
  console.log(queryParams);
  Object.keys(queryParams).forEach((key) => {
    queryString += `&${key}=${queryParams[key]}`;
  });
  console.log(`/api/getPackageList?sEcho=${current}${queryString}`);
  return request(`/api/getPackageList?sEcho=${current}${queryString}`);
}

export async function downExcelOfMember(params) {
  return request(`/api/downExcelOfMember`);
}

export async function queryCurrent({ sorter, filter, ...params }) {
  const { current, ...queryParams } = params;
  let queryString = '';
  console.log(queryParams);
  Object.keys(queryParams).forEach((key) => {
    queryString += `&${key}=${queryParams[key]}`;
  });
  console.log(`/api/getPackageList?sEcho=${current}${queryString}`);
  return request(`/api/getPackageList?sEcho=${current}${queryString}`);
}
